import { cn } from "~/lib/utils";

export function LoadingSpinner({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn("animate-spin", className)}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  );
}

export function Loading({ className }: { className?: string }) {
  return (
    <div
      className={cn("flex items-center justify-center space-x-2", className)}
    >
      <LoadingSpinner className="w-5 h-5 text-muted-foreground" />{" "}
      <span className="text-muted-foreground">Loading...</span>
    </div>
  );
}
